import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequesterService } from 'src/app/common/services/requester.service';

import { NzMessageService } from 'ng-zorro-antd/message';



@Component({
  selector: 'app-load-more-bank-modal',
  templateUrl: './load-more-bank-modal.component.html',
  styleUrls: ['./load-more-bank-modal.component.scss']
})
export class LoadMoreBankModalComponent implements OnInit {
  @Input()companyId: any;
  bankData: any[]=[];
  loading: boolean = false;

  constructor(private requesterService: RequesterService) { }

  ngOnInit(): void {
    if (this.companyId) {
      this.getBankData();
    }
  
 
  }

  getBankData(){
    this.loading = true;
    this.requesterService.request('get', 'vendor-companies/profile/'+this.companyId).subscribe({
      next: (res: any): void => {
        console.log('bank data', res);
        this.bankData = res.data?.BANK;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching bank data', error);
        this.loading = false;
      }
    });
  }

  
  getModalWidth(): string {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1440) {
      return '50%'; // For large screens
    } else if (screenWidth >= 1024) {
      return '60%'; // For desktop screens
    } else if (screenWidth >= 768) {
      return '80%'; // For tablet screens
    } else {
      return '90%'; // For small screens/mobile devices
    }
  }



}
