

  <div nz-row [nzGutter]="16" *ngIf="!loading">
    <div nz-col [nzXs]="24" [nzSm]="12" [nzMd]="12" [nzLg]="12" [nzXl]="12" *ngFor="let user of users" class="user-card">
      <nz-card class="h-100">
        <nz-card-meta
          [nzAvatar]="avatarTemplate"
          nzTitle="{{ user?.fullName }}"
          nzDescription="{{ user.email }}"
        ></nz-card-meta>
        
        <ng-template #avatarTemplate>
          <nz-avatar [nzIcon]="'user'"
          [nzSize]="64"
          style.backgroundColor="#1890ff"></nz-avatar>
        </ng-template>

        <div class="user-details">
          <p nz-typography *ngIf="user?.mobile">
            <i nz-icon nzType="phone" nzTheme="outline"></i> {{ user?.mobile }}
          </p>
        </div>
      </nz-card>
    </div>
  </div>
  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 4 }" *ngIf="loading"></nz-skeleton>