import { Injectable } from '@angular/core';
import { RequesterService } from './requester.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { modulesMap } from './module-permission-mapping';
import { StreamClientService } from './stream-client.service';
import { SocketService } from './socket.service';

export class User {
  _id: string;
  email: string;
  fullName: string;
  superAdmin: boolean;
  firstName: string;
  constructor(data: any) {
    this._id = data._id;
    this.email = data.email;
    this.fullName = data.fullName;
    this.firstName = data.firstName;
    this.superAdmin = data.superAdmin;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private requesterService: RequesterService,
    private router: Router,
    private streamClient: StreamClientService,
    private socketClientService: SocketService
  ) {
      this.syncPermissions();
      this.streamClient.connect();
  }


  public static get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return !!(localStorage.getItem('token') && user);
  }
  modulesMap = modulesMap;
  defaultPermittedModules = ['dashboard'];
  userData: User;
  error = '';
  tmpUser: User = AuthService.getUser();
  public profileUpdate = new BehaviorSubject<User | null>(this.tmpUser);
  currentPermissions: any = localStorage.getItem('permissions') && localStorage.getItem('permissions') !== 'undefined'  && JSON.parse(localStorage.getItem('permissions')) || {superAdmin: false, permissions: {}};
  public permissions$ = new BehaviorSubject<{permissions: any, superAdmin: boolean}>(this.currentPermissions);
  public static getUser() {
    if (AuthService.isLoggedIn) {
      const user = new User(JSON.parse(localStorage.getItem('user')));
      return user;
    }
    return null;
  }

  public static getPermissions() {
    return localStorage.getItem('permissions') && localStorage.getItem('permissions') !== 'undefined' && JSON.parse(localStorage.getItem('permissions')) || {superAdmin: false, permissions: {}};
  }
  public static isAdmin(): boolean {
    if (localStorage.getItem('admin')) {
      return JSON.parse(localStorage.getItem('admin'));
    }
    return false;
  }

  public hasAccessTo(moduleKey: string): boolean {
    const currentPermission = AuthService.getPermissions();
    if (currentPermission.superAdmin) {
      return true;
    }
    // Other Users
    const {permissions} = currentPermission;
    if (this.defaultPermittedModules.includes(moduleKey)) {
      return true;
    }
    const permittedKeys = Object.keys(permissions);
    // console.log('PERMITTED KEYS', permittedKeys, moduleKey);
    permittedKeys.push('chats')
    permittedKeys.push('bulk-upload')
    permittedKeys.push('job/test-jobs')
    const currentModuleMappedKey = this.modulesMap[moduleKey];
    // console.log('CURRENT MODULE MAPPED KEY', currentModuleMappedKey);
    const regEx = new RegExp(`^${currentModuleMappedKey}(\/[a-z\d]+)*$`);
    // console.log('CURRENT', currentModuleMappedKey, permittedKeys);
    // console.log(regEx, permittedKeys.some(x => regEx.test(x)));
    return permittedKeys.some(x => regEx.test(x));
  }

  public syncPermissions() {
    if (AuthService.getUser()) {
      console.log('>> PERMISSIONS Synced with Server');
      this.requesterService.request('get', 'auth/check-admin-access').subscribe(response => {
        this.updatePermissions(response.data);
      });
    }
  }

  signIn(email: string, password: string): Promise<any> {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise((resolve, reject) => {
      this.requesterService.request('post', 'auth/admins/login', { email, password })
        .subscribe(success => {
            console.log('login success', success);
            this.router.navigateByUrl('main/dashboard');
            this.processSuccessfullyLogin(success.data).then(user => {
                resolve(user);
              }).catch(error => {
                reject(error);
              }
            );
        }, error => {
          reject(error);
        });
  });
}

  processSuccessfullyLogin(loginResponse: any): Promise<User> {
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise<User>((resolve, reject) => {
      if (!loginResponse.token) {
        reject(new Error('No valid token'));
      } else {
        localStorage.setItem('token', loginResponse.token);
        this.userData = new User(loginResponse.user);
        localStorage.setItem('user', JSON.stringify(this.userData));
        this.profileUpdate.next(this.userData);
        this.syncPermissions();
        resolve(this.userData);
        this.streamClient.connect();
        this.socketClientService.disconnect();
        this.socketClientService.connect();
        this.socketClientService.authentication();

      }
    });
  }

  updatePermissions(permissions) {
    localStorage.setItem('permissions', JSON.stringify(permissions));
    this.permissions$.next(permissions);
  }

  SignOut() {
    // this.router.navigateByUrl('/auth/signin')

    this.requesterService.request('get', 'auth/logout').subscribe(success => {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.router.navigateByUrl('/auth/signin');
    }, error => {
      localStorage.clear();
      this.router.navigateByUrl('/auth/signin');
    });
    this.profileUpdate.next(null);
    this.socketClientService.disconnect();
  }

  getUserDetailsFromLocalStorage() {
    const user = JSON.parse(localStorage.getItem('user'));
    return user;
  }
}
