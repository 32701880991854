import { Component, OnInit } from '@angular/core';
import { SocketService } from './common/services/socket.service';
import { RequesterService } from './common/services/requester.service';
// import { SocketService } from './common/demos/socket.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isCollapsed = false;
  constructor(
    private socketService: SocketService,
    private requesterService: RequesterService
  ) { }
  ngOnInit() {
    this.socketService.connect();
    this.socketService.authentication();
    this.getCountryName();
  }

  getCountryName(){
    this.requesterService.httpClient.get('https://ipapi.co/json').subscribe((res) => {
      if(res) {
        localStorage.setItem('locationInfo', JSON.stringify(res));
      }
    })
  }

}
