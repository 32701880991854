<div nz-row class="row">
    <div nz-col nzSpan="24">
      <ng-container *ngIf="fromDrawer">
        <nz-page-header (nzBack)="onBack()" nzTitle="Team Members">
          <nz-page-header-extra>
            <div style="display: flex; justify-content: center;">


              <button
                nz-button
                nzType="primary"
                nzSize="medium"
                class="user-btn"

                (click)="showCreateUserModal()"
              >
                <span class="btn-content">
                  <i nz-icon nzType="user"></i>
                  <span>Create User</span>
                </span>
              </button>
            </div>

          </nz-page-header-extra>
        </nz-page-header>
      </ng-container>

      <ng-container *ngIf="!fromDrawer">
        <nz-page-header (nzBack)="onBack()" nzBackIcon nzTitle="Team Members">
          <nz-page-header-extra>
            <div style="display: flex; justify-content: center;">


              <button
                nz-button
                nzType="primary"
                nzSize="medium"
                class="user-btn"

                (click)="showCreateUserModal()"
              >
                <span class="btn-content">
                  <i nz-icon nzType="user"></i>
                  <span>Create User</span>
                </span>
              </button>
            </div>

          </nz-page-header-extra>
        </nz-page-header>
      </ng-container>


      <nz-divider></nz-divider>

      <nz-card [nzTitle]="null">
        <nz-table
          #basicTable
          [nzData]="users"
          [nzShowPagination]="true"
          class="scroll800-900 ta-left"
          nzTableLayout="fixed"
          [nzPageSize]="10"
          [nzShowSizeChanger]="true"
          [nzLoading]="userLoading"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Roles</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data">
              <td>
                {{ data?.fullName }}
              </td>
              <td>
                <nz-tag
                  nzColor="green"
                  *ngIf="data.admin"
                  style="margin-left: 10px"
                >
                  <span nz-icon nzType="crown" nzTheme="outline"></span>
                  Admin</nz-tag
                >

                <nz-tag
                  nzColor="gold"
                  *ngIf="data?.billingManager"
                  style="margin-left: 10px"
                >
                  <span nz-icon nzType="user" nzTheme="outline"></span>
                  Billing Manager</nz-tag
                >
              </td>

              <td>{{ data?.email }}</td>
              <td>{{ data?.mobile }}</td>
              <td>
                <nz-tag nzColor="green" *ngIf="data?.status" style="cursor: pointer;" nz-popconfirm="Make the user Inactive?" (nzOnConfirm)="toggleStatus(data)">
                  Active
                  </nz-tag>

                <nz-tag nzColor="red" *ngIf="!data?.status" style="cursor: pointer;" nz-popconfirm="Make the user Active?" (nzOnConfirm)="toggleStatus(data)">
                  Inactive
                  </nz-tag>
              </td>

              <td>
                <nz-space>
                  <button
                    nz-button
                    *nzSpaceItem
                    nz-dropdown
                    [nzDropdownMenu]="menu"
                    nzType="default"
                    nzTrigger="click"
                  >
                    <span nz-icon nzType="more" nzTheme="outline"></span>
                  </button>
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                      <li nz-menu-item (click)="showUpdateUserModal(data)">
                        <i
                          nz-icon
                          nzType="edit"
                          nzTheme="outline"
                          style="margin-right: 8px; color: #1890ff"
                        ></i>
                        Edit
                      </li>
                      <li
                        nz-menu-item
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure you want to delete this user?"
                        *ngIf="!data.superAdmin"
                        (nzOnConfirm)="deleteUser(data)"
                      >
                        <span
                          nz-icon
                          nzType="delete"
                          nzTheme="outline"
                          style="margin-right: 8px; color: #ff4d4f"
                        ></span>
                        Delete
                      </li>
                      <li
                        nz-menu-item
                        nz-popconfirm
                        nzPopconfirmTitle="Are you sure you want to reset the password?"
                        *ngIf="!data.superAdmin"
                        (nzOnConfirm)="resetPassword(data._id)"
                      >
                        <span
                          nz-icon
                          nzType="eye"
                          nzTheme="outline"
                          style="margin-right: 8px; color: #52c41a"
                        ></span>
                        Reset Password
                      </li>
                      <ng-container *ngIf="!data.superAdmin">
                        <li
                          nz-menu-item
                          nz-popconfirm
                          [nzPopconfirmTitle]="
                            !data.admin
                              ? 'Grant Admin Access?'
                              : 'Revoke Admin Access?'
                          "
                          (nzOnConfirm)="makeAdmin(data._id)"
                        >
                          <span
                            nz-icon
                            nzType="user"
                            style="margin-right: 8px; color: #faad14"
                          ></span>
                          {{
                            !data.admin ? "Make as admin" : "Revoke Admin Access"
                          }}
                        </li>
                      </ng-container>
                    </ul>
                  </nz-dropdown-menu>
                </nz-space>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-card>
    </div>
  </div>

  <!--  user create modal-->
  <nz-modal
    [(nzVisible)]="isCreateModalVisible"
    nzTitle="Create New User"
    (nzOnCancel)="handleCreateCancel()"
    (nzOnOk)="handleCreateOk()"
    [nzOkLoading]="createUserLoading"
  >
    <ng-container *nzModalContent>
      <form nz-form nzLayout="vertical">
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Email</nz-form-label>
              <nz-form-control>
                <input nz-input [(ngModel)]="newUser.email" name="email" />
                <div *ngIf="validationModalErrors.email" class="error">
                  {{ validationModalErrors.email }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>First Name</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  [(ngModel)]="newUser.firstName"
                  name="firstName"
                />
                <div *ngIf="validationModalErrors.firstName" class="error">
                  {{ validationModalErrors.firstName }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Last Name</nz-form-label>
              <nz-form-control>
                <input nz-input [(ngModel)]="newUser.lastName" name="lastName" />
                <div *ngIf="validationModalErrors.lastName" class="error">
                  {{ validationModalErrors.lastName }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Mobile</nz-form-label>
              <nz-form-control>
                <input nz-input [(ngModel)]="newUser.mobile" name="mobile" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>
  </nz-modal>

  <!--  user update modal-->
  <nz-modal
    [(nzVisible)]="isUpdateModalVisible"
    nzTitle="Update User"
    (nzOnCancel)="handleUpdateCancel()"
    (nzOnOk)="handleUpdateOk()"
    [nzOkLoading]="updateUserLoading"
  >
    <ng-container *nzModalContent>
      <form nz-form nzLayout="vertical">
        <div nz-row [nzGutter]="16">
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Email</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  [(ngModel)]="editUser.email"
                  name="email"
                />
                <div *ngIf="validationUpdateModalErrors.email" class="error">
                  {{ validationUpdateModalErrors.email }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>First Name</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  [(ngModel)]="editUser.firstName"
                  name="firstName"
                />
                <div *ngIf="validationUpdateModalErrors.firstName" class="error">
                  {{ validationUpdateModalErrors.firstName }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Last Name</nz-form-label>
              <nz-form-control>
                <input nz-input [(ngModel)]="editUser.lastName" name="lastName" />
                <div *ngIf="validationUpdateModalErrors.lastName" class="error">
                  {{ validationUpdateModalErrors.lastName }}
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzSpan]="12">
            <nz-form-item>
              <nz-form-label>Mobile</nz-form-label>
              <nz-form-control>
                <input nz-input [(ngModel)]="editUser.mobile" name="mobile" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </ng-container>
  </nz-modal>


