<form
  [formGroup]="companyDetailsForm"
  nz-form
  nzLayout="vertical"
  *ngIf="companyDetails"
>
  <nz-row nzGutter="16">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="companyName">Company Name</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="companyName"
            placeholder="Enter Company Name"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="address" nzRequired>Address</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="address"
            placeholder="Enter Address "
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzGutter="16">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="companyName" nzRequired>First Name</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="firstName"
            placeholder="Enter First Name"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="address" nzRequired>Last Name</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="lastName"
            placeholder="Enter Last Name "
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzGutter="16">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="companyName" nzRequired>Country</nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select Country"
            formControlName="countryName"
          >
            <nz-option
              [nzValue]="country.name"
              [nzLabel]="country.name"
              *ngFor="let country of countries"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="address" nzRequired>Zip Code</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="zipCode"
            placeholder="Enter Zip Code "
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzGutter="16">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="email">Email</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="email"
            placeholder="Enter Email "
            readonly
            style="background-color: #f0f2f5"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="mobile">Phone</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="mobile" placeholder="Enter Phone " />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
  <nz-row nzGutter="16">
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="invoiceCurrency" nzRequired
          >Select Invoice Currency</nz-form-label
        >
        <nz-form-control>
          <nz-select
            nzAllowClear
            nzShowSearch
            nzPlaceHolder="Enter Invoice Currency"
            formControlName="invoiceCurrency"
            [nzDisabled]="true"
          >
            <nz-option
              [nzValue]="curr.code"
              [nzLabel]="curr.name + ' (' + curr.code + ')'"
              *ngFor="let curr of currencies"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="websiteUrl">Website Url</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="websiteUrl"
            placeholder="Enter Website Url "

          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>


  <nz-row nzGutter="16">
    <!-- Upload Button Column -->
    <nz-col nzSpan="12">
      <nz-form-item>
        <nz-form-label nzFor="companyLogo">Company Logo</nz-form-label>
        <div>
          <nz-form-control>
            <nz-upload
              class="avatar-uploader"
              [nzAction]="mediaUploadURL"
              nzListType="picture-card"
              [nzShowUploadList]="false"
              [nzBeforeUpload]="beforeUploadLogo"
              [nzHeaders]="customHeaders"
              (nzChange)="handleChangeLogo($event)"
              [nzName]="'file'"
            >
              <div>
                <i nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
                <div style="margin-top: 8px">Upload</div>
              </div>
            </nz-upload>
          </nz-form-control>
        </div>
      </nz-form-item>
    </nz-col>

    <!-- Display Image Column -->
    <nz-col nzSpan="12" *ngIf="avatarUrl" class="image-container">
      <nz-form-item>
        <nz-form-label nzFor="companyLogo"></nz-form-label>
        <div>
          <nz-form-control>
            <div class="uploaded-image-wrapper">
              <img [src]="mediaBaseUrl + avatarUrl" alt="Uploaded Image" />
              <button
                (nzOnConfirm)="removeLogo(avatarUrl)"
                nz-popconfirm
                nzPopconfirmTitle="Are you sure to delete this?"
                nzPopconfirmPlacement="bottom"
                class="remove-image-button"
              >
                ✕
              </button>
            </div>
          </nz-form-control>
        </div>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<form
  [formGroup]="generalInfoForm"
  nz-form
  nzLayout="vertical"
  *ngIf="generalInfoDetails"
>
  <nz-row nzGutter="16">
    <nz-col nzSpan="24">
      <div nz-col [nzXs]="22" [nzSm]="22" [nzMd]="22" [nzLg]="22" [nzXl]="22">
        <h5 nz-typography class="mb-4">Ports by Country</h5>
        <p class="mb-4" *ngFor="let pc of countryWisePorts | keyvalue">
          <strong>{{ pc.key }} : </strong>
          <ng-container *ngFor="let port of pc.value">
            <nz-tag
              [nzColor]="'blue'"
              nzMode="closeable"
              (nzOnClose)="removePort(port)"
              >{{ port.name }}</nz-tag
            >
          </ng-container>
          <ng-container *ngIf="!pc.value.length">
            <nz-tag
              [nzColor]="'green'"
              nzMode="closeable"
              (nzOnClose)="removeCountry(pc.key)"
              >All Ports</nz-tag
            >
          </ng-container>
        </p>

        <h5 nz-typography class="mb-4">Regions by Country</h5>
        <p class="mb-4" *ngFor="let pc of countryWiseRegions | keyvalue">
          <strong>{{ pc.key }} : </strong>
          <ng-container *ngFor="let region of pc.value">
            <nz-tag
              [nzColor]="'orange'"
              nzMode="closeable"
              (nzOnClose)="removeRegion(region)"
              nz-popover
              [nzPopoverContent]="portsPop"
              [nzPopoverTitle]="region.name"
              >{{ region.name }}</nz-tag
            >
            <ng-template #portsPop>
              <p class="ports-popover">
                <nz-tag *ngFor="let item of region.ports">
                  {{item.name}}
                </nz-tag>
              </p>

            </ng-template>

          </ng-container>

        </p>
      </div>
    </nz-col>
    <nz-col nzSpan="24" class="mt-5">
      <!--Port selection Dropdown-->
      <nz-form-item>
        <nz-form-label nzFor="port">Add Port / Country / Region</nz-form-label>
        <nz-form-control>
          <nz-select
            (nzOnSearch)="searchPorts($event)"
            nzPlaceHolder="Please select"
            [nzAllowClear]="true"
            [nzLoading]="isPortLoading"
            #currSelectPort
            nzShowSearch
            [nzServerSearch]="true"
            [nzNotFoundContent]="notFoundPort"
            [ngModelOptions]="{ standalone: true }"
            [ngModel]="portSelection"
            (ngModelChange)="onPortSelect($event)"
          >
            <nz-option-group
              [nzLabel]="countryForPortTpl"
              *ngFor="let country of listOfPorts; index as i"
            >
              <nz-option
                *ngFor="let option of country.ports"
                [nzLabel]="option.name"
                [nzValue]="option._id"
                nzCustomContent
              >

              {{option.name}} <nz-tag *ngIf="option.type ==='region'" nzColor="orange">Region</nz-tag>
              </nz-option>
              <ng-template #countryForPortTpl>
                <label
                  nz-checkbox
                  (nzCheckedChange)="onCountrySelect($event, i)"
                  >{{ country.country }}</label
                >
              </ng-template>
            </nz-option-group>

            <ng-template #notFoundPort
              ><nz-empty *ngIf="!isPortLoading"></nz-empty
              >{{ isPortLoading ? "Please wait..." : "" }}</ng-template
            >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<form
  [formGroup]="serviceAndEquipmentForm"
  nz-form
  nzLayout="vertical"
  *ngIf="serviceAndEquipmentDetails"
>
  <nz-row nzGutter="16">
    <!-- <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="serviceName">
          Select Services
          <button nz-button nzType="link" (click)="pasteValue('service')" nz-tooltip="Paste from clipboard">
            <i nz-icon nzType="format-painter"></i>
          </button>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzServerSearch
            (nzOnSearch)="serviceSearchObs.next($event)"
            nzAllowClear
            nzPlaceHolder="Select a service"
            formControlName="serviceName"
            nzMode="tags"
          >
            <nz-option
              [nzLabel]="service"
              *ngFor="let service of allServices"
              [nzValue]="service"
            >
              {{ service }}</nz-option
            >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col> -->

    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="equipments">Select Equipments
          <button nz-button nzType="link" (click)="pasteValue('equipment')" nz-tooltip="Paste from clipboard">
          <i nz-icon nzType="format-painter"></i>
        </button></nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzServerSearch
            (nzOnSearch)="equipmentSearchObs.next($event)"
            nzPlaceHolder="Select a equipment"
            formControlName="equipments"
            nzMode="tags"
          >
            <nz-option
              [nzLabel]="equipment"
              *ngFor="let equipment of allEquipments"
              [nzValue]="equipment"
            >
              {{ equipment }}</nz-option
            >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="brands">Select Brands
          <button nz-button nzType="link" (click)="pasteValue('brand')" nz-tooltip="Paste from clipboard">
            <i nz-icon nzType="format-painter"></i>
          </button>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzServerSearch
            (nzOnSearch)="brandSearchObs.next($event)"
            nzPlaceHolder="Select a brand"
            formControlName="brands"
            nzMode="tags"
          >
            <nz-option
              [nzLabel]="brand"
              *ngFor="let brand of allBrands"
              [nzValue]="brand"
            >
              {{ brand }}</nz-option
            >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="brands">Select Parts
          <button nz-button nzType="link" (click)="pasteValue('part')" nz-tooltip="Paste from clipboard">
            <i nz-icon nzType="format-painter"></i>
          </button>
        </nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzServerSearch
            (nzOnSearch)="partsSearchObs.next($event)"
            nzPlaceHolder="Select parts"
            formControlName="parts"
            nzMode="tags"
          >
            <nz-option
              [nzLabel]="part"
              *ngFor="let part of allParts"
              [nzValue]="part"
            >
              {{ part }}</nz-option
            >
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<form
  [formGroup]="accreditationForm"
  nzLayout="vertical"
  *ngIf="accreditationDetails"
>
  <nz-row nzGutter="16">
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="certification"
          >Select Certification</nz-form-label
        >
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Select "
            formControlName="certification"
          >
            <nz-option
              nzValue="Class Certificates"
              nzLabel="Class Certificates"
            ></nz-option>
            <nz-option
              nzValue="ISO Certificates"
              nzLabel="ISO Certificates"
            ></nz-option>
            <nz-option
              nzValue="Other Certificates"
              nzLabel="Other Certificates"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzSpan="24">
      <nz-form-item>
        <nz-form-label nzFor="certification"
          >Click or drop files here</nz-form-label
        >
        <nz-form-control>
          <nz-upload
            [nzAction]="mediaUploadURL"
            nzListType="picture-card"
            [nzFileList]="fileList"
            [nzMultiple]="true"
            nzShowUploadList
            nzAccept=".png,.jpg,.jpeg"
            [nzBeforeUpload]="beforeUpload"
            (nzRemove)="handleRemove($event)"
            (nzChange)="handleChange($event)"
            [nzPreview]="handlePreview"
            [nzHeaders]="customHeaders"
            [nzName]="'file'"
          >
            <div>
              <i nz-icon [nzType]="loading ? 'loading' : 'plus'"></i>
              <div style="margin-top: 8px">Upload</div>
            </div>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <!-- Display uploaded images -->

    <ng-container *ngIf="images?.length > 0">
      <div
        *ngFor="let file of images; let i = index"
        style="position: relative; display: inline-block"
      >
        <img
          [src]="mediaBaseUrl + file"
          alt="Uploaded Image"
          style="width: 100px; height: 100px; margin: 5px"
        />
        <button
          (nzOnConfirm)="removeImage(i, file)"
          nz-popconfirm
          nzPopconfirmTitle="Are you sure to delete this?"
          nzPopconfirmPlacement="bottom"
          style="
            position: absolute;
            top: 0;
            right: 0;
            background: red;
            color: white;
            border: none;
            cursor: pointer;
          "
        >
          ✕
        </button>
      </div>
    </ng-container>
  </nz-row>
</form>
<form [formGroup]="bankForm" nz-form nzLayout="vertical" *ngIf="bankDetails">
  <nz-row nzGutter="16">
    <!-- Beneficiary Name -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="beneficiaryName">Beneficiary Name</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="beneficiaryName"
            placeholder="Beneficiary Name"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <!-- Bank Name -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="bankName">Bank Name</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="bankName"
            placeholder="Enter Bank Name"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzGutter="16">
    <!-- Routing Code -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="bankAddress">Routing Code</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="routingCode"
            placeholder="Enter Routing Code"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <!-- Bank Account Number -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="bankAccountNumber"
          >Bank Account Number</nz-form-label
        >
        <nz-form-control>
          <input
            nz-input
            formControlName="bankAccountNumber"
            placeholder="Enter Bank Account Number"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzGutter="16">
    <!-- Branch Code -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="branchCode">Branch Code</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="branchCode"
            placeholder="Enter Branch Code"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <!-- Swift Code -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="swiftCode">Swift Code</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="swiftCode"
            placeholder="Enter Swift Code"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzGutter="16">
    <!-- IBAN -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="iban">IBAN</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="ibanCode" placeholder="Enter IBAN" />
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <!-- IFSC Code -->
    <nz-col nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12">
      <nz-form-item>
        <nz-form-label nzFor="ifscCode">IFSC Code</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="ifscCode"
            placeholder="Enter IFSC Code"
          />
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>

  <nz-row nzGutter="16">
    <!-- Bank Address Code -->
    <nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
      <nz-form-item>
        <nz-form-label nzFor="routingCode">Bank Address</nz-form-label>
        <nz-form-control>
          <textarea
            nz-input
            id="bankAddress"
            formControlName="bankAddress"
            placeholder="Enter Bank Address"
          ></textarea>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>
