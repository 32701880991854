<div nz-row [nzGutter]="16" *ngIf="!loading">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="12" *ngFor="let bank of bankData; let i = index" class="bank-card ">
      <nz-card  class="h-100">
        
        
        <div nz-row [nzGutter]="16" >
          <div nz-col [nzXs]="20"
          [nzSm]="20"
          [nzMd]="18"
          [nzLg]="20"
          [nzXl]="20">
            <h6>Bank {{i+1}}</h6>
            </div>
            <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="6" [nzLg]="4" [nzXl]="4">
              <nz-space>
                <!-- <button
                  nz-button
                  *nzSpaceItem
                  nz-dropdown
                  [nzDropdownMenu]="bankMenu"
                  nzType="default"
                  nzTrigger="click"
                >
                  <span nz-icon nzType="more" nzTheme="outline"></span>
                </button> -->
                <!-- <nz-dropdown-menu #bankMenu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item (click)="updateBankDetails('Update Bank',bank)">
                      <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
                    </li>
                    <li nz-menu-item  nz-popconfirm
                    nzPopconfirmTitle="Are you sure to delete this?" (nzOnConfirm)="removeBankDetails(bank._id)">
                      <span nz-icon nzType="delete" nzTheme="outline"></span
                      >Remove
                    </li>
                  </ul>
                </nz-dropdown-menu> -->
              </nz-space>
            </div>
          </div>
        

        <div class="bank-details" >
          <div class="bank-item">
            <strong>Beneficiary Name : </strong> <span>{{bank?.beneficiaryName}}</span>
          </div>
          <div class="bank-item">
            <strong>Bank Name : </strong> <span>{{bank?.bankName}}</span>
          </div>
          <div class="bank-item">
            <strong>Bank Address : </strong> <span>{{bank?.bankAddress}}</span>
          </div>
          <div class="bank-item">
            <strong>Bank Account Number : </strong>
            <nz-tag [nzColor]="'green'">{{bank?.bankAccountNumber}}</nz-tag>
          </div>
          <div class="bank-item">
            <strong>Branch Code : </strong> <span>{{bank?.branchCode}}</span>
          </div>
          <div class="bank-item">
            <strong>Swift Code : </strong> <span>{{bank?.swiftCode}}</span>
          </div>
          <div class="bank-item">
            <strong>IBAN : </strong> <span>{{bank?.ibanCode}}</span>
          </div>
          <div class="bank-item">
            <strong>IFSC Code : </strong>
            <nz-tag [nzColor]="'cyan'">{{bank?.ifscCode}}</nz-tag>
          </div>
          <div class="bank-item">
            <strong>Routing Code : </strong><span>{{bank?.routingCode}}</span>
          </div>
        </div>
      </nz-card>
    </div>
    
  </div>
  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 4 }" *ngIf="loading"></nz-skeleton>

 