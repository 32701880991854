<nz-page-header nzBackIcon nzTitle="Company Profile" nzGhost *ngIf="!fromDrawer"></nz-page-header>
<div class="profile-container">
  <nz-card
    [nzBordered]="false"
    class="header-card h-100"
    [nzLoading]="profileLoading"
  >
    <nz-skeleton [nzActive]="true" *ngIf="profileLoading"></nz-skeleton>
    <!-- Edit icon in the top corner for small screens -->
    <button
      nz-button
      nzType="primary"
      nzShape="circle"
      class="edit-icon-small-screen"
      *ngIf="!profileLoading"
      (click)="createAndUpdate('Update Company', companyData?.PROFILE)"
    >
      <span nz-icon nzType="edit"></span>
    </button>
    <div style="width: 100%; display: flex; justify-content: flex-end;" *ngIf="syncEnabled">
      <button nz-button nzType="primary" nzDanger (click)="syncModalVisible = true">
        <span nz-icon nzType="cloud-sync"></span>
        Sync With Production
      </button>
    </div>

    <div nz-row [nzGutter]="16" nzAlign="middle" *ngIf="!profileLoading">
      <div
        nz-col
        [nzXs]="24"
        [nzSm]="8"
        [nzMd]="8"
        [nzLg]="8"
        [nzXl]="8"
        class="mb-2 company-logo"
      >
        <nz-avatar
          [nzSize]="128"
          nzIcon="shop"
          [nzSrc]="
            mediaBaseUrl + companyData?.PROFILE?.basicDetails?.companyLogo
          "
          [ngClass]="{
            'custom-avatar': companyData?.PROFILE?.basicDetails?.companyLogo
          }"
          [ngStyle]="
            companyData?.PROFILE?.basicDetails?.companyLogo
              ? {}
              : { 'background-color': '#87d068' }
          "
        ></nz-avatar>
      </div>
      <div
        nz-col
        [nzXs]="22"
        [nzSm]="14"
        [nzMd]="14"
        [nzLg]="14"
        [nzXl]="14"
        class="profile-box"
        *ngIf="!profileLoading"
      >
        <h1 nz-typography>{{ companyData?.PROFILE?.companyName }}</h1>

        <p nz-typography>
          <span nz-icon nzType="user" nzTheme="outline"></span>
          {{ companyData?.PROFILE?.user?.firstName }}
          {{ companyData?.PROFILE?.user?.lastName }}
        </p>
        <p nz-typography *ngIf="companyData?.PROFILE?.basicDetails?.address">
          <span nz-icon nzType="global" nzTheme="outline"></span>
          {{ companyData?.PROFILE?.basicDetails?.address }}
        </p>

        <p
          nz-typography
          *ngIf="companyData?.PROFILE?.basicDetails?.countryName"
        >
          <span nz-icon nzType="environment" nzTheme="outline"></span>
          {{ companyData?.PROFILE?.basicDetails?.countryName }} |
          {{ companyData?.PROFILE?.basicDetails?.zipCode }}
        </p>
        <p nz-typography *ngIf="companyData?.PROFILE?.invoiceCurrency">
          <span nz-icon nzType="dollar" nzTheme="outline"></span> Invoice
          Currency: {{ companyData?.PROFILE?.invoiceCurrency }}
        </p>

        <p nz-typography>
          <a href="{{ companyData?.PROFILE?.user?.email }}">
            <span nz-icon nzType="mail" nzTheme="outline"></span>
            {{ companyData?.PROFILE?.user?.email }}
          </a>
        </p>
        <p nz-typography *ngIf="companyData?.PROFILE?.user?.mobile">
          <a href="tel:{{ companyData?.PROFILE?.user?.mobile }}">
            <span nz-icon nzType="phone" nzTheme="outline"></span>
            {{ companyData?.PROFILE?.user?.mobile }}
          </a>
        </p>
        <p nz-typography *ngIf="companyData?.PROFILE?.websiteUrl">
          <a target="_blank" href="{{ companyData?.PROFILE?.websiteUrl }}">
            <span nz-icon nzType="link" nzTheme="outline"></span>
            {{ companyData?.PROFILE?.websiteUrl }}
          </a>
        </p>

        <p>
          <button nz-button nzType="primary" nzSize="small" (click)="openAiGeneratedProfile()">AI Profile Generator</button>
        </p>
      </div>
      <div nz-col [nzXs]="0" [nzSm]="0" [nzMd]="0" [nzLg]="2" [nzXl]="2">
        <button
          nz-button
          nzType="primary"
          nzShape="round"
          (click)="createAndUpdate('Update Company', companyData?.PROFILE)"
          class="edit-button-large-screen"
        >
          <span nz-icon nzType="edit"></span>Edit
        </button>
      </div>
    </div>
  </nz-card>

  <nz-divider nzText="Service Area" nzOrientation="left"></nz-divider>

  <nz-card
    [nzBordered]="false"
    class="info-card h-100"
    [nzLoading]="profileLoading"
  >
    <div class="card-container">
      <nz-skeleton [nzActive]="true" *ngIf="profileLoading"></nz-skeleton>
      <!-- Edit icon in the top corner for small screens -->
      <button
        nz-button
        nzType="primary"
        nzShape="circle"
        class="edit-icon-small-screen"
        (click)="createAndUpdate('Service Area', companyData?.GENERAL)"
      >
        <span nz-icon nzType="edit"></span>
      </button>
      <div
        nz-row
        [nzGutter]="16"
        class="scrollable-content"
        *ngIf="!profileLoading"
      >
        <!-- <nz-alert
            nz-col
            nzSpan="24"
            class="mb-4"
            nzType="info"
            nzShowIcon
            nzMessage="Important Note"
            nzDescription="Select all relevant ports of service to avoid missing any quote."
          ></nz-alert> -->
        <div nz-col [nzXs]="22" [nzSm]="22">
          <h5 nz-typography>Ports by Country</h5>
          <p *ngFor="let pc of countryWisePorts | keyvalue">
            <strong>{{ pc.key }} : </strong>
            <ng-container *ngFor="let port of pc?.value">
              <nz-tag [nzColor]="'blue'">{{ port }}</nz-tag>
            </ng-container>
            <ng-container *ngIf="!pc.value.length">
              <nz-tag [nzColor]="'green'">All Ports</nz-tag>
            </ng-container>
          </p>
        </div>

        <div nz-col [nzXs]="22" [nzSm]="22" *ngIf="hasRegionsByCountry">
          <h5 nz-typography>Regions by Country</h5>
          <p *ngFor="let pc of countryWiseRegions | keyvalue">
            <strong>{{ pc.key }} : </strong>
            <ng-container *ngFor="let port of pc?.value">
              <nz-tag [nzColor]="'orange'"
              nz-popover
              [nzPopoverContent]="portsPop"
              [nzPopoverTitle]="port.name"
              >{{ port.name }}</nz-tag>
              <ng-template #portsPop>
                <p class="ports-popover">
                  <nz-tag *ngFor="let item of port.ports">
                    {{item.name}}
                  </nz-tag>
                </p>

              </ng-template>
            </ng-container>
          </p>
        </div>
        <div nz-col [nzXs]="2" [nzSm]="2">
          <button
            nz-button
            nzType="primary"
            nzShape="round"
            (click)="createAndUpdate('Service Area', companyData?.GENERAL)"
            class="edit-button-large-screen"
          >
            <span nz-icon nzType="edit"></span>Edit
          </button>
        </div>
      </div>
    </div>
  </nz-card>

  <nz-divider
    nzText="Equipment, Brands & Parts"
    nzOrientation="left"
  ></nz-divider>

  <nz-card [nzBordered]="false" class="info-card h-100">
    <div class="card-container">
      <nz-skeleton [nzActive]="true" *ngIf="profileLoading"></nz-skeleton>
      <!-- Edit icon in the top corner for small screens -->
      <button
        *ngIf="!isServiceEquipmentBrandEmpty"
        nz-button
        nzType="primary"
        nzShape="circle"
        class="edit-icon-small-screen"
        (click)="
          createAndUpdate('Equipment, Brands & Parts', companyData?.SERVICE)
        "
      >
        <span nz-icon nzType="edit"></span>
      </button>

      <div
        nz-row
        [nzGutter]="16"
        class="scrollable-content"
        *ngIf="!profileLoading"
      >
        <!-- <nz-alert
            nz-col
            nzSpan="24"
            class="mb-4"
            nzType="info"
            nzShowIcon
            nzMessage="Important Note"
            nzDescription="Add relevant services, equipment, and brands to match you with the right jobs all the time"
          ></nz-alert> -->
        <div *ngIf="isServiceEquipmentBrandEmpty" nz-col nzSpan="24">
          <nz-empty
            nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            [nzNotFoundContent]="contentTpl"
            [nzNotFoundFooter]="footerTpl"
          >
            <ng-template #contentTpl>
              <span>
                Add some equipment, brands and parts to get Jobs matching
                your expertise
              </span>
            </ng-template>
            <ng-template #footerTpl>
              <button
                nz-button
                nzType="primary"
                (click)="
                  createAndUpdate(
                    'Equipment, Brands & Parts',
                    companyData?.SERVICE
                  )
                "
              >
                Create New
              </button>
            </ng-template>
          </nz-empty>
        </div>
        <ng-container *ngIf="!isServiceEquipmentBrandEmpty">
          <div nz-col nzSpan="22">
          <div nz-row>
              <!-- <div nz-col [nzXs]="24" [nzSm]="12">
                <h5 nz-typography>
                  Service Types
                  <button
                    nz-button
                    nzType="link"
                    nz-tooltip="{{
                      serviceCopyClicked
                        ? 'Copied to clipboard'
                        : 'Copy to clipboard'
                    }}"
                    (click)="onServiceCopyClick()"
                  >
                    <i
                      nz-icon
                      [nzType]="serviceCopyClicked ? 'check' : 'copy'"
                      nzTheme="outline"
                    ></i>
                  </button>
                </h5>
                <p nz-typography>
                  <ng-container
                    *ngFor="let service of companyData?.SERVICE?.serviceTypes"
                  >
                    <nz-tag [nzColor]="'green'">{{ service }}</nz-tag>
                  </ng-container>
                </p>
              </div> -->
              <div nz-col [nzXs]="24" [nzSm]="12">
                <h5 nz-typography>
                  Equipment Types
                  <button
                    nz-button
                    nzType="link"
                    nz-tooltip="{{
                      equipmentCopyClicked
                        ? 'Copied to clipboard'
                        : 'Copy to clipboard'
                    }}"
                    (click)="onEquipmentCopyClick()"
                  >
                    <i
                      nz-icon
                      [nzType]="equipmentCopyClicked ? 'check' : 'copy'"
                      nzTheme="outline"
                    ></i>
                  </button>
                </h5>
                <p nz-typography>
                  <ng-container
                    *ngFor="
                      let equipment of companyData?.SERVICE?.equipmentTypes
                    "
                  >
                    <nz-tag [nzColor]="'purple'">{{ equipment }}</nz-tag>
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzXs]="24" [nzSm]="12">
                <h5 nz-typography>
                  Brands
                  <button
                    nz-button
                    nzType="link"
                    nz-tooltip="{{
                      brandCopyClicked
                        ? 'Copied to clipboard'
                        : 'Copy to clipboard'
                    }}"
                    (click)="onBrandCopyClick()"
                  >
                    <i
                      nz-icon
                      [nzType]="brandCopyClicked ? 'check' : 'copy'"
                      nzTheme="outline"
                    ></i>
                  </button>
                </h5>
                <p nz-typography>
                  <ng-container
                    *ngFor="let brand of companyData?.SERVICE?.brands"
                  >
                    <nz-tag [nzColor]="'orange'">{{ brand }}</nz-tag>
                  </ng-container>
                </p>
              </div>
              <div nz-col [nzXs]="24" [nzSm]="12">
                <h5 nz-typography>
                  Part Types
                  <button
                    nz-button
                    nzType="link"
                    nz-tooltip="{{
                      partCopyClicked
                        ? 'Copied to clipboard'
                        : 'Copy to clipboard'
                    }}"
                    (click)="onPartCopyClick()"
                  >
                    <i
                      nz-icon
                      [nzType]="partCopyClicked ? 'check' : 'copy'"
                      nzTheme="outline"
                    ></i>
                  </button>
                </h5>
                <p nz-typography>
                  <ng-container
                    *ngFor="let part of companyData?.SERVICE?.parts"
                  >
                    <nz-tag [nzColor]="'blue'">{{ part }}</nz-tag>
                  </ng-container>
                </p>
              </div>
          </div>
        </div>

          <div nz-col nzSpan="2">
            <button
              nz-button
              nzType="primary"
              nzShape="round"
              (click)="
                createAndUpdate(
                  'Equipment, Brands & Parts',
                  companyData?.SERVICE
                )
              "
              class="edit-button-large-screen"
            >
              <span nz-icon nzType="edit"></span>Edit
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </nz-card>

  <nz-row>
    <nz-col [nzXs]="16" [nzSm]="16" [nzMd]="20" [nzLg]="20" [nzXl]="20">
      <nz-divider nzText="Accreditation" nzOrientation="left"></nz-divider>
    </nz-col>
    <nz-col
      [nzXs]="8"
      [nzSm]="8"
      [nzMd]="4"
      [nzLg]="4"
      [nzXl]="4"
      style="text-align: right"
    >
      <div>
        <!-- <button
            nz-button
            nzType="primary"
            nzShape="round"
            (click)="createAndUpdate('Add Accreditation')"
            class="mt-2"
          >
            <span nz-icon nzType="setting"></span>Manage
          </button> -->
      </div>
    </nz-col>
  </nz-row>

  <nz-skeleton [nzActive]="true" *ngIf="profileLoading"></nz-skeleton>
  <div nz-row [nzGutter]="16" *ngIf="!profileLoading">
    <!-- <nz-alert
        *ngIf="companyData?.CERTIFICATE.length"
        nz-col
        nzSpan="24"
        class="mb-4"
        nzType="info"
        nzShowIcon
        nzMessage="Important Note"
        nzDescription="Increase your chances to get an invite for the quotes when you keep your accreditations  up to date."
      ></nz-alert> -->
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="8"
      [nzLg]="8"
      [nzXl]="8"
      *ngFor="
        let item of companyData?.CERTIFICATE | slice : 0 : 3;
        let i = index
      "
      class="info-card"
    >
      <nz-card [nzBordered]="false" class="h-100">
        <div nz-row [nzGutter]="16">
          <div
            nz-col
            [nzXs]="20"
            [nzSm]="20"
            [nzMd]="18"
            [nzLg]="20"
            [nzXl]="20"
          >
            <h6>Accreditation {{ i + 1 }}</h6>
            <p>{{ item?.certification }}</p>
          </div>
          <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="6" [nzLg]="4" [nzXl]="4">
            <nz-space>
              <!-- <button
                  nz-button
                  *nzSpaceItem
                  nz-dropdown
                  [nzDropdownMenu]="menu"
                  nzType="default"
                  nzTrigger="click"
                >
                  <span nz-icon nzType="more" nzTheme="outline"></span>
                </button> -->
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li
                    nz-menu-item
                    (click)="createAndUpdate('Update Accreditation', item)"
                  >
                    <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
                  </li>
                  <li
                    nz-menu-item
                    nz-popconfirm
                    nzPopconfirmTitle="Are you sure to delete this?"
                    (nzOnConfirm)="removeAccreditation(item)"
                  >
                    <span nz-icon nzType="delete" nzTheme="outline"></span
                    >Remove
                  </li>
                </ul>
              </nz-dropdown-menu>
            </nz-space>
          </div>
        </div>

        <!-- <div
            nz-row
            [nzGutter]="16"
            class="btn-wrapper"
            *ngIf="item.files && item?.files?.length > 0"
          >
            <div
              nz-col
              [nzXs]="24"
              [nzSm]="24"
              [nzMd]="12"
              [nzLg]="12"
              [nzXl]="12"
              *ngFor="let img of item?.files"
            >
              <img
                src="{{ mediaBaseUrl }}{{ img }}"
                alt="Image 1"
                class="image-item"
                nz-image
              />
            </div>
          </div> -->
        <div
          nz-row
          [nzGutter]="16"
          class="btn-wrapper scrollable-wrapper"
          *ngIf="item.files && item?.files?.length > 0"
        >
          <div
            nz-col
            [nzXs]="24"
            [nzSm]="24"
            [nzMd]="12"
            [nzLg]="12"
            [nzXl]="12"
            *ngFor="let img of item?.files"
            class="image-container"
          >
            <div class="image-wrapper">
              <img
                src="{{ mediaBaseUrl }}{{ img }}"
                alt="Image 1"
                class="image-item"
              />

              <a class="download-icon" download (click)="handleDownload(img)">
                <i nz-icon nzType="download" nzTheme="outline"></i>
              </a>
            </div>
          </div>
        </div>
      </nz-card>
    </div>

    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="24"
      *ngIf="
        companyData?.CERTIFICATE.length === 0 ||
        companyData?.CERTIFICATE === undefined
      "
    >
      <nz-card [nzBordered]="false" class="info-card">
        <!-- <nz-alert
            nz-col
            nzSpan="24"
            class="mb-4"
            nzType="info"
            nzShowIcon
            nzMessage="Important Note"
            nzDescription="Increase your chances to get an invite for the quotes when you keep your accreditation  up to date."
          ></nz-alert> -->
        <nz-empty
          nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          [nzNotFoundContent]="contentTpl"
          [nzNotFoundFooter]="footerTpl"
        >
          <ng-template #contentTpl>
            <span> No Accreditation Details Found </span>
          </ng-template>
          <ng-template #footerTpl>
            <!-- <button
                nz-button
                nzType="primary"
                (click)="createAndUpdate('Add Accreditation')"
              >
                Create New
              </button> -->
          </ng-template>
        </nz-empty>
      </nz-card>
    </div>
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="24"
      *ngIf="companyData?.CERTIFICATE.length > 3"
      style="text-align: center; margin-top: 20px"
    >
      <button
        nz-button
        nzType="default"
        nzDanger
        (click)="loadMoreAccreditation(companyData?.CERTIFICATE)"
      >
        {{ companyData?.CERTIFICATE.length - 3 }} more
      </button>
    </div>
  </div>

  <nz-row>
    <nz-col [nzXs]="16" [nzSm]="16" [nzMd]="20" [nzLg]="20" [nzXl]="20">
      <nz-divider nzText="Users" nzOrientation="left"></nz-divider>
    </nz-col>
    <nz-col
      [nzXs]="8"
      [nzSm]="8"
      [nzMd]="4"
      [nzLg]="4"
      [nzXl]="4"
      style="text-align: right"
    >
      <div>
        <button
          nz-button
          nzType="primary"
          nzShape="round"
          (click)="manageUsers()"
          class="mt-2"
        >
          <span nz-icon nzType="setting" nzTheme="outline"></span>
          Manage
        </button>
      </div>
    </nz-col>
  </nz-row>

  <nz-skeleton [nzActive]="true" *ngIf="profileLoading"></nz-skeleton>
  <div nz-row [nzGutter]="16" *ngIf="!profileLoading">
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="8"
      *ngFor="let user of users | slice : 0 : 3"
      class="user-card"
    >
      <nz-card [nzBordered]="false" class="h-100">
        <nz-card-meta
          [nzAvatar]="avatarTemplate"
          nzTitle="{{ user?.fullName }}"
          nzDescription="{{ user.email }}"
        ></nz-card-meta>
        <ng-template #avatarTemplate>
          <nz-avatar
            [nzIcon]="'user'"
            [nzSize]="64"
            style.backgroundColor="#1890ff"
          ></nz-avatar>
        </ng-template>

        <div class="user-details">
          <p nz-typography *ngIf="user?.mobile">
            <i nz-icon nzType="phone" nzTheme="outline"></i> {{ user?.mobile }}
          </p>
        </div>
      </nz-card>
    </div>
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="24"
      *ngIf="users.length > 3"
      style="text-align: center"
    >
      <button nz-button nzType="default" nzDanger (click)="loadMoreUser(users)">
        {{ users.length - 3 }} more
      </button>
    </div>
  </div>

  <nz-row>
    <nz-col [nzXs]="16" [nzSm]="16" [nzMd]="20" [nzLg]="20" [nzXl]="20">
      <nz-divider nzText="Bank" nzOrientation="left"></nz-divider>
    </nz-col>
    <nz-col
      [nzXs]="8"
      [nzSm]="8"
      [nzMd]="4"
      [nzLg]="4"
      [nzXl]="4"
      style="text-align: right"
    >
      <div>
        <!-- <button
            nz-button
            nzType="primary"
            nzShape="round"
            (click)="createAndUpdate('Add Bank')"
            class="mt-2"
          >
            <span nz-icon nzType="setting"></span> Manage
          </button> -->
      </div>
    </nz-col>
  </nz-row>

  <div nz-row [nzGutter]="16">
    <!-- <nz-alert
        *ngIf="companyData?.BANK.length"
        nz-col
        nzSpan="24"
        class="mb-4"
        nzType="info"
        nzShowIcon
        nzMessage="Important Note"
        nzDescription="Add your bank details so that buyers know where to pay you"
      ></nz-alert> -->
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="8"
      *ngFor="let bank of companyData?.BANK | slice : 0 : 3; let i = index"
      class="bank-card"
    >
      <nz-card [nzBordered]="false" class="h-100">
        <nz-skeleton [nzActive]="true" *ngIf="profileLoading"></nz-skeleton>
        <div nz-row [nzGutter]="16" *ngIf="!profileLoading">
          <div
            nz-col
            [nzXs]="20"
            [nzSm]="20"
            [nzMd]="18"
            [nzLg]="20"
            [nzXl]="20"
          >
            <h6>Bank {{ i + 1 }}</h6>
          </div>
          <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="6" [nzLg]="4" [nzXl]="4">
            <nz-space>
              <!-- <button
                  nz-button
                  *nzSpaceItem
                  nz-dropdown
                  [nzDropdownMenu]="bankMenu"
                  nzType="default"
                  nzTrigger="click"
                >
                  <span nz-icon nzType="more" nzTheme="outline"></span>
                </button> -->
              <nz-dropdown-menu #bankMenu="nzDropdownMenu">
                <ul nz-menu nzSelectable>
                  <li
                    nz-menu-item
                    (click)="createAndUpdate('Update Bank', bank)"
                  >
                    <i nz-icon nzType="edit" nzTheme="outline"></i>Edit
                  </li>
                  <li
                    nz-menu-item
                    nz-popconfirm
                    nzPopconfirmTitle="Are you sure to delete this?"
                    (nzOnConfirm)="removeBankDetails(bank._id)"
                  >
                    <span nz-icon nzType="delete" nzTheme="outline"></span
                    >Remove
                  </li>
                </ul>
              </nz-dropdown-menu>
            </nz-space>
          </div>
        </div>

        <div class="bank-details" *ngIf="!profileLoading">
          <div class="bank-item">
            <strong>Beneficiary Name : </strong>
            <span>{{ bank?.beneficiaryName }}</span>
          </div>
          <div class="bank-item">
            <strong>Bank Name : </strong> <span>{{ bank?.bankName }}</span>
          </div>
          <div class="bank-item">
            <strong>Bank Address : </strong>
            <span>{{ bank?.bankAddress }}</span>
          </div>
          <div class="bank-item">
            <strong>Bank Account Number : </strong>
            <nz-tag [nzColor]="'green'">{{ bank?.bankAccountNumber }}</nz-tag>
          </div>
          <div class="bank-item">
            <strong>Branch Code : </strong> <span>{{ bank?.branchCode }}</span>
          </div>
          <div class="bank-item">
            <strong>Swift Code : </strong> <span>{{ bank?.swiftCode }}</span>
          </div>
          <div class="bank-item">
            <strong>IBAN : </strong> <span>{{ bank?.ibanCode }}</span>
          </div>
          <div class="bank-item">
            <strong>IFSC Code : </strong>
            <nz-tag [nzColor]="'cyan'">{{ bank?.ifscCode }}</nz-tag>
          </div>
          <div class="bank-item">
            <strong>Routing Code : </strong><span>{{ bank?.routingCode }}</span>
          </div>
        </div>
      </nz-card>
    </div>

    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="24"
      *ngIf="companyData?.BANK.length === 0 || companyData?.BANK === undefined"
    >
      <nz-card [nzBordered]="false" class="bank-card h-100">
        <!-- <nz-alert
            nz-col
            nzSpan="24"
            class="mb-4"
            nzType="info"
            nzShowIcon
            nzMessage="Important Note"
            nzDescription="Add your bank details so that buyers know where to pay you"
          ></nz-alert> -->
        <nz-empty
          nzNotFoundImage="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          [nzNotFoundContent]="contentTpl"
          [nzNotFoundFooter]="footerTpl"
        >
          <ng-template #contentTpl>
            <span> No Bank Details Found </span>
          </ng-template>
          <ng-template #footerTpl>
            <!-- <button
                nz-button
                nzType="primary"
                (click)="createAndUpdate('Add Bank')"
              >
                Create New
              </button> -->
          </ng-template>
        </nz-empty>
      </nz-card>
    </div>
    <div
      nz-col
      [nzXs]="24"
      [nzSm]="24"
      [nzMd]="24"
      *ngIf="companyData?.BANK.length > 3"
      style="text-align: center; margin-top: 20px"
    >
      <button
        nz-button
        nzType="default"
        nzDanger
        (click)="loadMoreBank(companyData?.BANK)"
      >
        {{ companyData?.BANK.length - 3 }} more
      </button>
    </div>
  </div>
</div>


<!--Modal to enter Website Url only -->
<nz-modal
  [(nzVisible)]="websiteModalVisible"
  nzTitle="Enter Website URL"
  (nzOnCancel)="websiteModalVisible = false"
  (nzOnOk)="onWebsiteUpdate()"
  [nzOkLoading]="isWebsiteUpdating"
>
<ng-container *nzModalContent>
  <form nz-form [formGroup]="websiteForm">
    <nz-form-item>
      <nz-form-control>
        <input
          nz-input
          formControlName="websiteUrl"
          placeholder="Enter Website URL"
        />
      </nz-form-control>
    </nz-form-item>
  </form>
</ng-container>

</nz-modal>

<!--MODAL for Vendor sync-->
<nz-modal
  [(nzVisible)]="syncModalVisible"
  nzTitle=""
  (nzOnCancel)="syncModalVisible = false"
  (nzOnOk)="onSyncWithProduction()"
  [nzOkLoading]="isSyncing"
  nzOkText="Sync"
  [nzOkLoading]="isSyncing"
>
<ng-container *nzModalContent>
  <nz-descriptions nzTitle="Sync With Production" nzBordered [nzColumn]="1">
    <nz-descriptions-item nzTitle="Sync Type">
      {{syncNewVendor ? 'New Vendor' : 'Existing Vendor'}}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Company Name">
      {{syncData.currentVendorData?.companyName}}
    </nz-descriptions-item>
    </nz-descriptions>
    <p *ngIf="syncData?.targetVendorData?.companyName">
      <nz-alert
        nzType="info"
        nzMessage="Note"
        nzDescription="This will update Ports and Equipment, Brands & Parts. Please make sure you have the correct data before proceeding."
      ></nz-alert>
    </p>

    <p *ngIf="!syncData?.targetVendorData">
      <nz-alert
        nzType="info"
        nzMessage="Note"
        nzDescription="This will create a new vendor with the same data as the current vendor. Please make sure you have the correct data before proceeding."
      ></nz-alert>
    </p>
  </ng-container>
</nz-modal>
