import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-port-selector',
  templateUrl: './port-selector.component.html',
  styleUrls: ['./port-selector.component.scss']
})
export class PortSelectorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
