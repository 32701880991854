import { Component, Input, OnInit } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzModalService } from "ng-zorro-antd/modal";
import { RequesterService } from "src/app/common/services/requester.service";
import { environment } from "src/environments/environment";
import { ConfigurationService } from "src/app/common/services/configuration.service";

@Component({
  selector: "app-load-more-accreditation-modal",
  templateUrl: "./load-more-accreditation-modal.component.html",
  styleUrls: ["./load-more-accreditation-modal.component.scss"],
})
export class LoadMoreAccreditationModalComponent implements OnInit {
  @Input() companyId: any;
  mediaUploadURL = environment.apiUrl + "/api/media";
  mediaBaseUrl = ConfigurationService.mediaBaseUrl;
  accreditations: any[] = [];
  loading: boolean = false;

  constructor(
    private requesterService: RequesterService,
    
  ) {}

  ngOnInit(): void {
    if (this.companyId) {
    this.getProfileData();}
  }
  getProfileData(): void {
    this.loading = true;
    this.requesterService.request('get', 'vendor-companies/profile/'+this.companyId).subscribe({
      next: (res: any): void => {
        console.log('bank data', res);
        this.accreditations = res.data?.CERTIFICATE;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching bank data', error);
        this.loading = false;
      }
    });
  }
  

  getModalWidth(): string {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1440) {
      return "50%"; // For large screens
    } else if (screenWidth >= 1024) {
      return "60%"; // For desktop screens
    } else if (screenWidth >= 768) {
      return "80%"; // For tablet screens
    } else {
      return "90%"; // For small screens/mobile devices
    }
  }

  handleDownload(img: string): void {
    const imageUrl = `${this.mediaBaseUrl}${img}`;

    // Open image in a new tab
    const newTab = window.open(imageUrl, "_blank");
    if (!newTab) {
      console.error("Popup blocked. Please allow popups for this site.");
      return;
    }
  }
}
