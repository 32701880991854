import { Component, Input, OnInit } from '@angular/core';
import { RequesterService } from 'src/app/common/services/requester.service';

@Component({
  selector: 'app-load-more-user-modal',
  templateUrl: './load-more-user-modal.component.html',
  styleUrls: ['./load-more-user-modal.component.scss']
})
export class LoadMoreUserModalComponent implements OnInit {
  @Input()companyId: any;
  

  loading:boolean = false;
  users:any[] = [];
  constructor(private requesterService: RequesterService) {
   
   }

  ngOnInit(): void {

  
      if (this.companyId) {
        this.getUsers();
      }
    
  
   

   
  }
  getUsers(){
    this.loading = true;
    this.requesterService.request('get', `users/vendors/company-wise-users?company=${this.companyId}`).subscribe({
      next: (res: any): void => {
        console.log('users', res);
        this.users = res.data;
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching users', error);
        this.loading = false;
      }
    });
  }

 

}
