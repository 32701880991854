import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { RequesterService } from 'src/app/common/services/requester.service';

@Component({
  selector: 'app-ai-vendor-profile',
  templateUrl: './ai-vendor-profile.component.html',
  styleUrls: ['./ai-vendor-profile.component.scss']
})
export class AiVendorProfileComponent implements OnInit {

  generated: boolean = false;
  @Input() data: any;
  isGenerating: boolean = false;
  generatedDAta: any;
  inputVisible: any = {
    'service_types': false,
    'equipment_types': false,
    'brands': false,
    'part_types': false,
  }
  inputValue: any = {
    'service_types': '',
    'equipment_types': '',
    'brands': '',
    'part_types': '',
  }
  isSaving: boolean = false;
  @ViewChild('inputServiceTypes', { static: false }) inputServiceTypes?: ElementRef;
  @ViewChild('inputEquipmentTypes', { static: false }) inputEquipmentTypes?: ElementRef;
  @ViewChild('inputBrands', { static: false }) inputBrands?: ElementRef;
  @ViewChild('inputPartTypes', { static: false }) inputPartTypes?: ElementRef;
  showInput(type: string) {
    this.inputVisible[type] = true;
    if (type === 'service_types') {
      setTimeout(() => {
        this.inputServiceTypes?.nativeElement.focus();
      }, 10);
    } else if (type === 'equipment_types') {
      setTimeout(() => {
        this.inputEquipmentTypes?.nativeElement.focus();
      }, 10);
    } else if (type === 'brands') {
      setTimeout(() => {
        this.inputBrands?.nativeElement.focus();
      }, 10);
    } else if (type === 'part_types') {
      setTimeout(() => {
        this.inputPartTypes?.nativeElement.focus();
      }, 10);
    }
  }
  handleInputConfirm(type: string) {
    if (this.inputValue[type] && this.generatedDAta[type].indexOf(this.inputValue[type]) === -1) {
      this.generatedDAta[type] = [...this.generatedDAta[type], this.inputValue[type]];
    }
    this.inputValue[type] = '';
    this.inputVisible[type] = false;
  }
  manual: boolean = false;
  manualData: string = '';
  constructor(
    private requesterService: RequesterService,
    private drawerRef: NzDrawerRef,
    private msg: NzMessageService,
    private nzModalService: NzModalService
  ) { }

  ngOnInit(): void {

    console.log(this.data);
    // this.generateAIProfile(this.data.generate);
  }

  generateAIProfile(generate: boolean = false, manual: boolean = false): void {
    // console.log('Generating AI Profile');
    this.isGenerating = true;
    this.generatedDAta = null;
    this.generated = false;
    this.requesterService.request('post', 'vendor-companies/generate-profile', {
      company: this.data.companyId,
      generate,
      manualData: manual ? this.manualData : null
    }).subscribe((res: any) => {
      console.log(res);
      this.generated = true;
      this.isGenerating = false;
      this.generatedDAta = {
        'equipment_types': res.data.equipment,
        'brands': res.data.brands,
        'parts': res.data.partTypes,
      }
    } , (err) => {
      console.log(err);
      this.generated = true;
      this.isGenerating = false;
      this.generatedDAta = null;
    } );
  }

  removeItem(type: string, index: number) {
    console.log(type, index);
    this.generatedDAta[type] = this.generatedDAta[type].filter((item: any, i: number) => i !== index);
  }

  saveAIProfileConfirm(): void {
    // give 2 options, one is to save keeping existing data, other is to save with new data
    const modal = this.nzModalService.create({
      nzTitle: 'Save AI Profile',
      nzContent: 'Do you want to save AI Profile replacing existing data or merging with existing data?',
      nzOkText: 'Replace',
      nzFooter: [
        {
          label: 'Cancel',
          onClick: () => modal.destroy()
        },
        {
          label: 'Merge',
          type: 'primary',
          onClick: () => {
            this.saveAIProfile(true);
            modal.destroy();
          }
        },
        {
          label: 'Replace',
          type: 'primary',
          danger: true,
          onClick: () => {
            this.saveAIProfile(false);
            modal.destroy();
          }
        }
      ]
      // nzOnOk: () => {
      //   this.saveAIProfile();
      // },
      // nzCancelText: 'Merge',
      // nzOnCancel: () => {
      //   this.saveAIProfile(true);
      // }
    });
  }

  saveAIProfile(keepExisting: boolean = false): void {
    console.log('Saving AI Profile');
    console.log(this.generatedDAta);
    this.isSaving = true;

    this.requesterService.request('post', 'vendor-companies/update-section', {
      company: this.data.companyId,
      section: 'SERVICE',
      equipmentTypes: this.generatedDAta.equipment_types,
      brands: this.generatedDAta.brands,
      parts: this.generatedDAta.parts,
      keepExisting
    }).subscribe((res: any) => {
      console.log(res);
      this.drawerRef.close(true);
      this.msg.success('AI Profile Saved Successfully');
      this.isSaving = false;
    } , (err) => {
      console.log(err);
      this.msg.error('Error Saving AI Profile');
      this.isSaving = false;
    } );
  }

}
