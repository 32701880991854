import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { AuthService } from "src/app/common/services/auth.service";
import { RequesterService } from "src/app/common/services/requester.service";

interface User {
  _id?: string;
  email: string;
  firstName: string;
  lastName: string;
  mobile: string;
  isUpdating?: boolean;
  isAdmin: boolean;
  isPasswordResetLoading?: boolean;
  isBillingManager?: boolean;
  company: string;
}

@Component({
  selector: "app-vendor-team-members",
  templateUrl: "./vendor-team-members.component.html",
  styleUrls: ["./vendor-team-members.component.scss"],
})
export class VendorTeamMembersComponent implements OnInit {
  userDetails: any;
  usersLoading: boolean = false;
  users: any[] = [];
  newUser: User = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    isAdmin: false,
    company: "",
  };

  editUser: User = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    isAdmin: false,
    company: "",
  };
  validationErrors = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
  };

  validationModalErrors = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
  };
  validationUpdateModalErrors = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
  };
  isPasswordResetLoading: boolean = false;
  createUserLoading: boolean = false;
  updateUserLoading: boolean = false;
  isCreateModalVisible: boolean = false;
  isUpdateModalVisible: boolean = false;
  isPendingUserRequestsModalVisible: boolean = false;
  pendingUserRequestsLoading: boolean = false;
  requestedUsers: any[] = [];
  pendingUserCount: number = 0;
  userLoading: boolean = false;
  @Input() companyId: string = "";
  @Input() fromDrawer: boolean = false;

  constructor(
    private requesterService: RequesterService,
    private msg: NzMessageService,
    private route: ActivatedRoute,
    private notificationService: NzNotificationService
  ) {
    this.userDetails = AuthService.getUser();
    this.route.params.subscribe((params) => {
      if(!this.companyId) {
        // get the company id from the route params
        this.companyId = params["companyId"];
      }

    });
  }

  ngOnInit(): void {
    // Get the team members from the API
    this.getTeamMembers();
  }

  getTeamMembers() {
    // Call the API to get the team members
    this.usersLoading = true;
    // Implement API call to get all users
    console.log("Getting all users");
    this.requesterService
      .request(
        "get",
        `users/vendors/company-wise-users?company=${this.companyId}`
      )
      .subscribe((res) => {
        this.users = res.data;

        this.usersLoading = false;
      });
  }

  resetNewUser() {
    this.newUser = {
      _id: "",
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
      isAdmin: false,
      company: "",
    };
  }

  onBack(): void {
    window.history.back();
  }

  showCreateUserModal() {
    this.isCreateModalVisible = true;
  }
  showUpdateUserModal(user: User) {
    this.isUpdateModalVisible = true;
    // patch the user details to the form
    this.editUser = user;
  }

  createUser(): void {
    // api call to create user
    console.log("create user", this.newUser);
    this.newUser.company = this.companyId;
    // Reset validation messages
    this.validationModalErrors = {
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
    };

    // Validate email
    if (!this.newUser.email) {
      this.validationModalErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(this.newUser.email)) {
      this.validationModalErrors.email = "Invalid email format";
    }

    // Validate first name
    if (!this.newUser.firstName) {
      this.validationModalErrors.firstName = "First name is required";
    }

    // Validate last name
    if (!this.newUser.lastName) {
      this.validationModalErrors.lastName = "Last name is required";
    }
    // If there are validation errors, don't proceed with the API call
    if (
      this.validationModalErrors.email ||
      this.validationModalErrors.firstName ||
      this.validationModalErrors.lastName
    ) {
      this.createUserLoading = false;
      return;
    }

    this.requesterService
      .request("post", "users/vendors", this.newUser)
      .subscribe({
        next: (res) => {
          console.log("response", res);
          this.getTeamMembers();
          this.createUserLoading = false;
          this.isCreateModalVisible = false;
          this.msg.success("User created successfully");
        },
        error: (err: any) => {
          console.log("error", err);
          this.msg.error("Error creating user");
          this.createUserLoading = false;
          this.isCreateModalVisible = false;
        },
        complete: () => {
          console.log("Create user operation completed");
        },
      });
  }

  updateUser(): void {
    // api call to update user
    console.log("update user", this.updateUser);
    // Reset validation messages
    this.validationUpdateModalErrors = {
      email: "",
      firstName: "",
      lastName: "",
      mobile: "",
    };

    // Validate email
    if (!this.editUser.email) {
      this.validationUpdateModalErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(this.editUser.email)) {
      this.validationUpdateModalErrors.email = "Invalid email format";
    }

    // Validate first name
    if (!this.editUser.firstName) {
      this.validationUpdateModalErrors.firstName = "First name is required";
    }

    // Validate last name
    if (!this.editUser.lastName) {
      this.validationUpdateModalErrors.lastName = "Last name is required";
    }
    // If there are validation errors, don't proceed with the API call
    if (
      this.validationUpdateModalErrors.email ||
      this.validationUpdateModalErrors.firstName ||
      this.validationUpdateModalErrors.lastName
    ) {
      this.updateUserLoading = false;
      return;
    }

    this.requesterService
      .request("put", `users/vendors/${this.editUser._id}`, this.editUser)
      .subscribe({
        next: (res) => {
          // console.log("response", res);

          this.msg.success("User update successfully");
          this.updateUserLoading = false;
          this.isUpdateModalVisible = false;
          this.resetNewUser();
          this.getTeamMembers();
        },
        error: (err: any) => {
          console.log("error", err);
          if( err?.error?.message) {
            this.msg.error(err.error.message);
          } else {
            this.msg.error("Unknown Error (Please contact dev team)");
          }
          this.getTeamMembers();
          this.updateUserLoading = false;
        },
        complete: () => {
          console.log("Create user operation completed");
        },
      });
  }

  deleteUser(user: User) {
    // Implement delete logic here
    console.log("Deleting user:", user);
    // Should not delete the current user
    if (user._id === this.userDetails?._id) {
      this.msg.error("Cannot delete current user");
      return;
    }
    this.requesterService
      .request("delete", `users/vendors/${user._id}`)
      .subscribe(() => {
        this.msg.success("User deleted successfully");
        this.getTeamMembers();
      });
  }

  resetPassword(userId?: string): void {
    // api call to reset password

    this.requesterService
      .request("post", "auth/reset-password-by-admin", { user: userId })
      .subscribe({
        next: (res: any) => {
          this.msg.success("Mail sent successfully for password reset");
        },
        error: (err) => {
          this.msg.error("Error resetting password");
        },
      });
  }

  makeAdmin(userId: string): void {
    // Implement make admin logic here
    console.log("Making user admin:", userId);
    this.requesterService
      .request("post", `users/vendors/toggle-admin-access`, {
        user: userId,
        company: this.companyId,
      })
      .subscribe({
        next: (res: any) => {
          this.msg.success(`${res.message} successfully`);
          this.getTeamMembers();
        },
        error: (err) => {
          this.msg.error("Error making user admin");
        },
      });
  }

  handleCreateOk() {
    // Implement create user logic here
    this.createUserLoading = true;
    this.createUser();
    this.resetNewUser();
  }

  handleCreateCancel() {
    this.isCreateModalVisible = false;
    this.resetNewUser();
  }

  handleUpdateOk() {
    // Implement create user logic here
    this.updateUserLoading = true;
    this.updateUser();
  }

  handleUpdateCancel() {
    this.isUpdateModalVisible = false;
    this.resetNewUser();
  }

  // Function to calculate dynamic height
  calculateDynamicHeight(): string {
    const screenHeight = window.innerHeight;
    const modalHeight = Math.min(screenHeight * 0.8, 600); // Example: 80% of screen height or max 600px
    return `${modalHeight}px`;
  }

  toggleStatus(user: any) {
    // Implement toggle status logic here
    console.log("Toggling status for user:", user);
    this.requesterService
      .request("put", `users/admins/${user._id}/toggle-status`).subscribe(res => {
        this.msg.success("User status updated successfully");
        this.getTeamMembers();
      } , err => {
        this.msg.error("Error updating user status");
      });
  }
}
