<div nz-row [nzGutter]="16" *ngIf="!loading">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="12" [nzXl]="12"
     *ngFor="let item of accreditations; let i = index" class="info-card">
      <nz-card  class="h-100">
        

        <div nz-row [nzGutter]="16" >
          <div nz-col [nzXs]="20"
          [nzSm]="20"
          [nzMd]="18"
          [nzLg]="20"
          [nzXl]="20">
           
            <h6>Accreditation {{i +1}}</h6>
            <p>{{item?.certification}}</p>
           
          </div>
          <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="6" [nzLg]="4" [nzXl]="4">
            <nz-space>
              <!-- <button
                nz-button
                *nzSpaceItem
                nz-dropdown
                [nzDropdownMenu]="menu"
                nzType="default"
                nzTrigger="click"
              >
                <span nz-icon nzType="more" nzTheme="outline"></span>
              </button> -->
              
            </nz-space>
          </div>

        </div>
        

        <!-- <div nz-row [nzGutter]="16" class="btn-wrapper" *ngIf="item.files">
          <div nz-col [nzXs]="8" *ngFor="let img of item.files">
            <img
              src="{{mediaBaseUrl}}{{img}}"
              alt="Image 1"
              class="image-item"
              nz-image
            />
          </div>
          
        </div> -->

        <div
        nz-row
        [nzGutter]="16"
        class="btn-wrapper scrollable-wrapper"
        *ngIf="item.files && item?.files?.length > 0"
      >
        <div
          nz-col
          [nzXs]="24"
          [nzSm]="24"
          [nzMd]="12"
          [nzLg]="12"
          [nzXl]="12"
          *ngFor="let img of item?.files"
          class="image-container"
        >
          <div class="image-wrapper">
          
            <img
              src="{{ mediaBaseUrl }}{{ img }}"
              alt="Image 1"
              class="image-item"
              
            />
           
            <a
              class="download-icon"
             (click)="handleDownload(img)"
              
            >
              <i nz-icon nzType="download"  nzTheme="outline"></i>
            </a>
          </div>
        </div>
      </div>
      </nz-card>
    </div>
   
   
  </div>

  <nz-skeleton [nzAvatar]="true" [nzParagraph]="{ rows: 4 }" *ngIf="loading"></nz-skeleton>